


@font-face {
  font-family: carnacLight;
  src: url(fonts/Carnac-Light.otf);
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");



body::-webkit-scrollbar {
  display: none;
}

.App {
  display: flex;
  flex-direction: row;
  width: 100vw;
  margin: 0px;
}

.example > * > * {
  transition: all 500ms;
}


.pokeCard #colorBg div {
  height: 30%;
  width: 100%;
}

.pokeCard #colorBg div:nth-child(1) {
  background-color: var(--color2);
}

.pokeCard #colorBg div:nth-child(2) {
  height: 40%;
  background-color: var(--color3);
}

.pokeCard #colorBg div:nth-child(3) { 
  background-color: var(--color4);
}

.pokeCard input {
  border-radius: 15px;
  border: 0 solid #121212;
  background-color: #f1f1f1;
  color: #121212;
  font-size: 1.6vw;
  padding: 5px 20px;
  margin: 5px 0 0px 0;
  border: 1px solid var(--hsp3);
  text-align: center;
  text-transform: capitalize;
  font-family: var(--font1);
  outline: none;
}

.pokeCard .type {
  font-size: 1.2vw;
  color: var(--hsp3);
}

.pokeCard #my-canvas {
  border-radius: 15px;
  padding: 5px;
  margin-bottom: 2px;
  /* width: 100px;
  height: 100px; */
}

.pokeCard #buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.pokeCard #buttons > * {
  padding: 10px;
  font-size: 1.4rem;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  background-color: #f1f1f1;
  color: #121212;
  border-radius: 15px;
  border: 1px solid var(--hsp3);
  outline: none;
  cursor: pointer;
}

.pokeCard input {
  text-align: center;
}

.labelInput {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}

.labelInput label {
  color: var(--hsp3);
}

.pokeCard #numLine {
  padding: 0;
  margin: 5px 0 5px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* align-items: left; */
}

.pokeCard #numLine input::-webkit-outer-spin-button,
.pokeCard #numLine input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pokeCard #numLine input {
  border-radius: 5px;
  width: fit-content;
}

.pokeCard #numLine button {
  color: #f1f1f1;
  padding: 0 0px 0 0px;
  margin: 0 10px 0 10px;
  background: none;
  border: #00000000;
  filter: drop-shadow(0 0 1px #121212);
  cursor: pointer;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

#example {
  margin-left: 30vw;
  width: 70vw;
  display: flex;
  flex-direction: column;
  font-family: var(--font1);
  scroll-behavior: smooth;
}

#example h1 {
  font-size: 3vw;
  font-family: "Raleway", sans-serif;
}

#example main p {
  font-size: 1.2vw;
  margin-bottom: 30px;
  width: 80%;
}

.example button {
  padding: 15px 20px 15px 20px;
  font-size: 1vw;
  margin-top: 50px;
  margin-right: 20px;
  border-radius: 5px;
  border: 0px solid #121212;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  transition: all 200ms ease-in-out;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}

#moon {
  display: none;
}

#example main #backdrop {
  background-color: var(--mainDark);
  /* mix-blend-mode: color; */
  opacity: 0.0;
  width: 70vw;
  height: 100vh;
  position: absolute;
  left: 30.2vw;
  top: 0;
  z-index: 0;
}

#example main {
  background-image: var(--color2);
  background-size: 300vh auto;
  background-position: left;
  padding: 0px 10% 20px 10%;
  display: flex;
  flex-direction: row;
  gap: 2vw;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

#example main section {
  width: 35vw;
  border-radius: 16px;
  z-index: 1;
  color: var(--hsp2);
}

#example main #artCanvas {
  width: 28vw;
  height: auto;
  z-index: 9;
  transition: all 0.2s ease-in-out;
}

#example main #artCanvas:hover {
  filter: drop-shadow(0 0 15px var(--color1)) contrast(1.3) saturate(1.5);
}

#example main #colorFilter {
  position: absolute;
  top: 0;
  width: 28vw;
  height: 28vw;
  background-color: var(--mainDark);
  opacity: 0.85;
  border-radius: 100px;
  mix-blend-mode: darken;
  filter: blur(100px);
  transition: all 1s ease-in-out;
  opacity: 0.1;
}

#example main #artCanvas #colorFilter:hover {
  z-index: 8;
  opacity: 0.2;
}

#example #cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2%;
  z-index: 9;
  width: 100%;
  min-height: fit-content;
  padding: 50px 2vw 50px 2vw;
  box-sizing: border-box;
}

#example #cards > div:active {
  animation: shine 1s ease-in-out;
}

#example #cards div p {
  font-size: 24px;
  position: relative;
  top: calc(24px / 2);
}

#example #cards svg {
  font-size: 24px;
  position: relative;
  top: calc(24px - 80%);
  left: calc(50% - 24px);
}

#example section {
  min-height: 100px;
  z-index: 2;
}

#example footer #faIcons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

#example a {
  color: var(--hsp2);
}

@media screen and (max-width: 768px) {

  .pokeCard #buttons {
    margin-top: 20px;
  }

  .pokeCard input,
  .pokeCard #buttons button {
    font-size: 6vw;
  }

  .labelInput {
    padding-top: 20px;
  }

  #example,
  .poke {
    width: 100vw;
    position: relative;
    margin: 0;
    left: 0;
    border: 0px solid #121212;
  }

  .pokeCard .type {
    font-size: 6vw;
  }

  #example {
    margin: 0;
    padding: 0;
  }

  #example main {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0;
    margin: 0;
    background-size: 650%;
    background-position: left;
    height: fit-content;
    gap: 0;
  }

  #example main section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 50px 0 100px 0;
  }

  #example main h1 {
    font-size: 10vw;
  }

  #example main p,
  #example button,
  #example #scrollDown {
    font-size: 5vw;
  }

  #example main h1,
  #example main p {
    width: 90%;
    margin: 0 0 50px 0;
  }

  #example main #backdrop {
    width: 100%;
    height: 200vh;
    padding: 0;
    left: 0;
    top: 0;
    margin: 0;
    /* display: none; */
  }

  #example main section #customButtons {
    margin: 0;
  }

  #example main #artCanvas {
    /* display: none; */
    width: 100%;
    height: auto;
    padding: 40px 10% 0px 10%;
    box-sizing: border-box;
    /* padding: 10%; */
  }

  #example #cards {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    /* flex-direction: column; */
    justify-items: center;
  }

  #example #cards #loadMore {
    grid-column: 1;
    width: 90%;
  }

  #example #cards div {
    width: 90%;
  }

  #example #cards div p {
    font-size: 24px;
  }

  #example #cards,
  footer {
    z-index: 9;
  }
}
