:root {
  --color1: #f1f1f1;
  --color2: #f1f1f1;
  --color3: #f1f1f1;
  --color4: #f1f1f1;
  --mainDark: #18181a;
  --hsp2: #121212;
  --hsp3: #121212;
  --hsp4: #121212;
  --artURL: url("https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/937.png");
  --bgURL: url(../public/bgDark.webp);
  --font1: carnacLight;
  --pkmnInfoBg: "rgba(255, 255, 255, 0.8)";
}
@font-face {
  font-family: carnacLight;
  src: url(fonts/Carnac-Light.otf);
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCP.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCP.ttf) format('truetype');
}
html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  font-family: var(--font1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}
.bmc-btn-container {
  position: fixed;
  right: 3%;
  top: 3%;
  z-index: 1000;
}
.bmc-btn-container.game {
  position: absolute;
  height: 100px;
  width: 100px;
}
.bmc-btn-container .bmc-btn {
  font-size: 1rem;
  height: fit-content;
  padding: 10px 2vw;
  justify-content: center;
  background-color: var(--color3) !important;
  color: var(--hsp3);
  transition: all 200ms ease-in-out;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  font-family: carnacLight !important;
  animation: wiggleBMC 1s ease-in-out infinite;
}
@keyframes wiggleBMC {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.bmc-btn-container .bmc-btn:hover {
  background-color: var(--mainDark) !important;
  color: var(--color1);
}
.bmc-btn-container .bmc-btn span {
  line-height: 1;
  width: fit-content;
}
@keyframes buttonAnimation {
  0% {
    box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.1);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.1);
  }
}
.App {
  display: flex;
  flex-direction: row;
  width: 100vw;
  margin: 0px;
}
.poke {
  width: 30vw;
  position: fixed;
  z-index: 9999;
}
.pokeCard {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  gap: 2vh;
  font-family: var(--font1);
  border-right: 4px solid var(--mainDark);
}
.pokeCard canvas {
  display: none;
  image-rendering: pixelated;
}
.pokeCard #imgData {
  display: block;
  height: 10vw;
  width: 10vw;
  image-rendering: pixelated;
}
.pokeCard #logo #pokeball {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 2rem;
  left: 3rem;
  background: radial-gradient(circle, #000000 0%, #000000 70%, #00000000 70%, #00000000 100%);
  transform: rotate(45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}
.pokeCard #logo #pokeball.animated {
  animation: pokeball 2s ease-in-out 1;
}
@keyframes pokeball {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(calc(45deg + 4 * 360deg));
  }
}
.pokeCard #logo #pokeball #pokeballMiddle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #ffffff;
  position: absolute;
  border: 3px solid #000000;
  z-index: 2;
}
.pokeCard #logo #pokeball #pokeballTop {
  background: linear-gradient(90deg, var(--color2) 0%, var(--color2) 33%, var(--color3) 33%, var(--color3) 66%, var(--color4) 66%, var(--color4) 100%);
  height: 100%;
  width: 100%;
  transform: translateY(-5%);
  z-index: 1;
}
.pokeCard #logo #pokeball #pokeballBottom {
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 33%, #e7e7e7 33%, #e7e7e7 66%, #c7c7c7 66%, #c7c7c7 100%);
  height: 100%;
  width: 100%;
  z-index: 0;
  transform: translateY(5%);
}
.pokeCard #profileMenu {
  position: absolute;
  right: 2rem;
  top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.pokeCard #profileMenu > * {
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.pokeCard #profileMenu > * button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--font1);
  font-weight: 1000;
  gap: 0.4vw;
  border: none;
  border-radius: 0.4rem;
  padding: 0.6rem 0.8rem;
  background-color: var(--color2);
  animation: buttonAnimation 1s infinite;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  color: var(--hsp2);
}
.pokeCard #profileMenu > * button svg {
  font-size: 1.2rem;
  padding: 0;
}
.pokeCard #profileMenu > * button:hover {
  background-color: var(--color3);
  color: var(--hsp3);
}
.pokeCard #profileMenu #saveBtn.saved button {
  background-color: transparent;
  animation: none;
  border: 2px solid var(--hsp2);
}
.pokeCard #profileMenu #profileBtn button {
  max-width: 2rem;
  animation: none;
  border: 2px solid var(--hsp2);
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
}
.pokeCard #profileMenu #profileBtn button img {
  width: 100%;
}
.pokeCard #profileMenu #profileBtn button svg {
  font-size: 1.5rem;
}
.pokeCard #profileMenu #profileBtn button:hover {
  border: 2px solid var(--anti_hsp2);
  background-color: white;
}
.pokeCard #profileDashboard {
  background: #00000000;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  width: 70vw;
  margin-left: 30vw;
  height: 100vh;
  border: none;
  box-sizing: border-box;
  color: var(--color1);
  padding: 1rem 6rem;
}
.pokeCard #profileDashboard .backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000050;
  z-index: -9999;
  backdrop-filter: blur(30px);
}
.pokeCard #profileDashboard[open] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pokeCard #profileDashboard #profileDashboardContent {
  background-color: #171717;
  border-radius: 2rem;
  max-height: 95%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 1rem 0;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardHeader {
  position: relative;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardHeader #closeProfileDashboard {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 1.5rem;
  font-weight: 1000;
  color: var(--color1);
  border: 2px solid var(--color1);
  background-color: var(--mainDark);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardHeader #closeProfileDashboard:hover {
  background-color: var(--color3);
  color: var(--hsp3);
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0 2rem;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserImg {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserImg img {
  width: 100%;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserDetails {
  text-align: left;
  gap: 0.5rem;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser .signOutBtn {
  background-color: var(--color3);
  color: var(--hsp3);
  border: none;
  border-radius: 0.4rem;
  padding: 0.6rem 0.8rem;
  font-family: var(--font1);
  font-weight: 1000;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  margin-left: 2rem;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser .signOutBtn:hover {
  background-color: var(--color4);
  color: var(--hsp4);
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved {
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr));
  gap: 1rem;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon {
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  background: linear-gradient(120deg, #363636ac 0%, #36363630 33%, #36363620 66%, #36363660 100%);
  border: 2px solid transparent;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon.current {
  border: 2px solid var(--color3);
  background: linear-gradient(120deg, #363636aa 0%, #3636368f 33%, #36363699 66%, #36363640 100%);
  background-color: var(--color3);
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon .clickOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: transparent;
  z-index: 1;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon .delete {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  transition: all 200ms ease-in-out;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  border: 2px solid var(--color1);
  z-index: 2;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon .delete:hover {
  background-color: var(--mainDark);
  color: var(--color1);
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon .shiny {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon .palette {
  position: static;
  left: 1rem;
  top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon .palette div {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  margin: 0.5rem 0;
}
.pokeCard #profileDashboard #profileDashboardContent #profileDashboardSaved #profileDashboardSavedList .savedPokemon:hover {
  background: linear-gradient(120deg, #363636ac 0%, #36363630 33%, #36363620 66%, #36363660 100%);
  background-color: var(--color1);
  color: var(--mainDark);
  transform: scale(1.05);
}
.pokeCard #suggestionsWrapper {
  position: absolute;
  top: 105%;
  width: 100%;
  overflow-y: scroll;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: var(--color3);
  border: 1px solid var(--hsp3);
  box-sizing: border-box;
}
.pokeCard #suggestionsWrapper.empty {
  display: none;
}
.pokeCard #suggestionsWrapper #suggestions {
  max-height: 200px;
  width: 100%;
}
.pokeCard #suggestionsWrapper #suggestions .suggestion {
  margin-top: 1px;
  top: 0;
  position: relative;
  width: 100%;
  border: none;
  padding: 10px 0;
  font-family: var(--font1);
  font-size: 1.2rem;
  min-height: 60px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
}
.pokeCard #suggestionsWrapper #suggestions .suggestion:hover {
  border-radius: 1rem;
  transform: scale(0.9);
}
.pokeCard #suggestionsWrapper #suggestions .suggestion:first-child {
  margin-top: 0;
}
.pokeCard #suggestionsWrapper #suggestions .suggestion span img {
  width: 40px;
  vertical-align: bottom;
  margin-left: 10px;
}
.pokeCard #colorBg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.pokeCard #colorBg > * {
  transition: all 200ms ease-in-out;
}
.pokeCard #hideWhileLoading {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
}
.pokeCard .loading svg {
  animation: loading 600ms ease-in-out infinite;
  font-size: 2rem;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pokeCard .labelInput {
  position: relative;
}
.pokeCard .labelInput #shinyBtn {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 1%;
  top: 45%;
  border: none;
  border-radius: 0 0.9rem 0.9rem 0;
  width: 15%;
  height: 2rem;
  cursor: pointer;
  font-size: 1.4rem;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  color: #121212;
  background: none;
}
.pokeCard .labelInput #shinyBtn:hover,
.pokeCard .labelInput #shinyBtn.shiny {
  opacity: 1;
  color: var(--color2);
  animation: wiggle 1s ease-in-out infinite;
}
@keyframes wiggle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.pokeCard .labelInput input::placeholder {
  text-transform: none;
  opacity: 0.5;
  font-size: 1.1rem;
  transform: translateY(-0.2rem);
}
.pokeCard .tag {
  display: none;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  --colorTag: color-mix(in oklab, var(--color3), var(--mainDark));
  background-color: var(--colorTag);
  color: #f1f1f1;
  font-family: var(--font1);
  z-index: 1;
  margin: 0;
  min-height: 1.2rem;
  font-weight: 600;
  position: absolute;
  bottom: 0;
}
.pokeCard .tag.empty {
  visibility: hidden;
}
.pokeCard #buttons > * {
  transition: all 200ms ease-in-out;
}
.pokeCard #buttons > *:hover {
  transform: scale(1.05);
}
#example:nth-child(2n + 1) {
  background: var(--color2);
  color: var(--hsp2);
}
#example:nth-child(2n) {
  background: var(--mainDark);
  color: var(--color1);
}
.example main #customButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.example main #customButtons button:hover {
  transform: scale(1.05);
}
.example main h1 {
  font-weight: 1000;
}
.example main #scrollDown {
  color: var(--hsp4);
  background-color: var(--color4);
  padding: 15px 20px 15px 20px;
  font-size: 1vw;
  margin-top: 0px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-family: "Raleway", sans-serif;
  font-weight: 1000;
}
.example main #darkMode {
  color: var(--color1);
  background-color: var(--mainDark);
  border-radius: 50px;
  padding: 13px 14px 13px 14px;
  margin: 0;
  font-size: 1rem;
  width: fit-content;
  border: 2px solid var(--hsp2);
  font-weight: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  justify-content: space-between;
  position: relative;
  transition: all 200ms ease-in-out;
}
.example #cards {
  background-color: var(--mainDark) !important;
}
.example #cards .colorCard {
  width: 100%;
  height: 150px;
  color: var(--color1);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}
.example #cards .colorCard.hidden {
  display: none;
}
.example #cards .colorCard .color2::after {
  content: var(--color2word);
}
.example #cards .colorCard .color3::after {
  content: var(--color3word);
}
.example #cards .colorCard .color4::after {
  content: var(--color4word);
}
.example #cards .colorCard .color5::after {
  content: var(--color5word);
}
.example #cards .colorCard .color6::after {
  content: var(--color6word);
}
.example #cards .colorCard .color7::after {
  content: var(--color7word);
}
.example #cards .colorCard .color8::after {
  content: var(--color8word);
}
.example #cards .colorCard .color9::after {
  content: var(--color9word);
}
.example #cards .colorCard .color10::after {
  content: var(--color10word);
}
.example #cards .colorCard:nth-child(1) {
  background-color: var(--color2);
  color: var(--hsp2);
}
.example #cards .colorCard:nth-child(2) {
  background-color: var(--color3);
  color: var(--hsp3);
}
.example #cards .colorCard:nth-child(3) {
  background-color: var(--color4);
  color: var(--hsp4);
}
.example #cards .colorCard:nth-child(4) {
  background-color: var(--color5);
  color: var(--hsp5);
}
.example #cards .colorCard:nth-child(5) {
  background-color: var(--color6);
  color: var(--hsp6);
}
.example #cards .colorCard:nth-child(6) {
  background-color: var(--color7);
  color: var(--hsp7);
}
.example #cards .colorCard:nth-child(7) {
  background-color: var(--color8);
  color: var(--hsp8);
}
.example #cards .colorCard:nth-child(8) {
  background-color: var(--color9);
  color: var(--hsp9);
}
.example #cards .colorCard:nth-child(9) {
  background-color: var(--color10);
  color: var(--hsp10);
}
.example #cards #loadMore {
  font-family: "Raleway", sans-serif;
  line-height: 2;
  font-size: 2rem;
  padding: 20px 1vw;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  grid-column: 1/4;
  margin: 20px 0;
  background-color: var(--color3);
  color: var(--hsp3);
}
.example #shareWidgets {
  padding: 40px 3vw;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 6vw;
  background-color: var(--mainDark);
  color: var(--color1);
}
.example #shareWidgets .tweet-popup {
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 20px 2vw;
  border-radius: 16px;
  box-sizing: border-box;
  background: var(--color1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.example #shareWidgets .tweet-popup .tweetLogo {
  width: 2rem;
  height: auto;
  margin-bottom: 20px;
  border-radius: 50%;
}
.example #shareWidgets .tweet-popup .tweet-input {
  width: 100%;
  border: 1px solid var(--hsp4);
  color: var(--hsp4);
  background: var(--anti_hsp4);
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  min-height: 200px;
  font-family: carnacLight;
  scrollbar-width: none;
  -ms-overflow-style: none;
  resize: vertical;
}
.example #shareWidgets .tweet-popup .tweet-input::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}
.example #shareWidgets .tweet-popup .tweet-button {
  background-color: var(--color3);
  color: var(--hsp3);
  align-self: flex-end;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  font-family: carnacLight;
}
.example #shareWidgets .tweet-popup .tweet-button:hover {
  filter: brightness(1.2);
  transform: scale(1.1);
}
.example #shareWidgets p {
  font-size: 1.2rem;
  font-family: carnacLight;
  margin: 20px 0;
}
.example #pkmnInfo {
  min-height: 400px;
  height: fit-content;
  min-height: 11rem;
  position: relative;
  box-sizing: border-box;
  background: var(--color2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px 3vw;
  gap: 2vw;
}
.example #pkmnInfo .txtWrapper {
  color: var(--hsp2);
  position: relative;
  font-family: "Raleway", sans-serif;
  margin: 20px 2vw;
  width: 40%;
}
.example #pkmnInfo .txtWrapper h2 {
  font-size: 2.5rem;
}
.example #pkmnInfo .txtWrapper #types {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  justify-content: flex-start;
}
.example #pkmnInfo .txtWrapper #types.singleType div:nth-child(2) {
  display: none;
}
.example #pkmnInfo .txtWrapper #types div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: var(--color3);
  color: var(--hsp3);
  font-size: 1.2rem;
  font-family: carnacLight;
  text-transform: capitalize;
}
.example #pkmnInfo .txtWrapper #types div:nth-child(2) {
  background: var(--color4);
  color: var(--hsp4);
}
.example #pkmnInfo .txtWrapper #descriptionTxt {
  min-height: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.example #pkmnInfo .txtWrapper p {
  font-size: 1.2rem;
  font-family: carnacLight;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p {
  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: var(--color3);
  color: var(--hsp3);
  width: fit-content;
  font-weight: 1000;
  border: 2px solid var(--hsp2);
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.red {
  background-color: #ff1111;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.blue {
  background-color: #1111ff;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.yellow {
  background-color: #ffd733;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.gold {
  background-color: #daa520;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.silver {
  background-color: #c0c0c0;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.crystal {
  background-color: #4fd9ef;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.ruby {
  background-color: #a00000;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.sapphire {
  background-color: #0000a0;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.emerald {
  background-color: #00a000;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.firered {
  background-color: #ff7327;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.leafgreen {
  background-color: #73ff27;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.diamond {
  background-color: #aaaaff;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.pearl {
  background-color: #ffaaaa;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.platinum {
  background-color: #999999;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.heartgold {
  background-color: #eda72e;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.soulsilver {
  background-color: #aeb7c2;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.black,
.example #pkmnInfo .txtWrapper #flavorTxtNav p.black-2 {
  background-color: #151515;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.white,
.example #pkmnInfo .txtWrapper #flavorTxtNav p.white-2 {
  background-color: #f1f1f1;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.x {
  background-color: #025da6;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.y {
  background-color: #ea1a3e;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.omega-ruby {
  background-color: #ab2813;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.alpha-sapphire {
  background-color: #26649c;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.sun {
  background-color: #f1912b;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.moon {
  background-color: #5599ca;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.ultra-sun {
  background-color: #e95b2b;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.ultra-moon {
  background-color: #226db5;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.lets-go-pikachu {
  background-color: #f5da26;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.lets-go-eevee {
  background-color: #d4924b;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.sword {
  background-color: #00a1e9;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.shield {
  background-color: #bf004f;
  color: #121212;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.legends-arceus {
  background-color: #36597b;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.scarlet {
  background-color: #f34134;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav p.violet {
  background-color: #8334b7;
  color: #ffffff;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav button {
  margin-top: 0;
}
.example #pkmnInfo .txtWrapper #flavorTxtNav button[disabled] {
  opacity: 0.5;
  cursor: auto;
}
.example #pkmnInfo .imgWrapper {
  height: 400px;
  width: 50%;
  background: var(--color3);
  background-position: center;
  background-size: cover;
  z-index: 3;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  position: relative;
}
.example #pkmnInfo .imgWrapper:hover #hiddenPkmn {
  filter: brightness(1);
}
.example #pkmnInfo .imgWrapper #hiddenPkmn {
  position: relative;
  background-position: center;
  background-size: 50% auto;
  background-repeat: no-repeat;
  height: 100%;
  width: 120%;
  z-index: 1;
  filter: brightness(0);
  transition: all 400ms ease-in-out;
}
.example #pkmnInfo .imgWrapper .colorFilter {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0.5;
  z-index: 0;
  mix-blend-mode: color;
}
.example #pkmnInfo .imgWrapper #pkmnCry {
  width: 100%;
  background-color: #f1f3f4;
}
.example .imAd {
  background-color: var(--mainDark);
  color: var(--color1);
  padding: 40px 4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  gap: 5vw;
  font-family: "Raleway", sans-serif;
}
.example .imAd > * {
  width: 50%;
  text-align: right;
}
.example .imAd #info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  gap: 0;
}
.example .imAd #info button {
  width: 100%;
}
.example .imAd h2 {
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.5;
  margin: 0;
}
.example .imAd #title {
  line-height: 2;
  font-weight: 400;
  font-size: 1.2rem;
  flex-wrap: wrap;
  word-wrap: wrap;
  display: inline;
}
.example .imAd #title #titleWords > span {
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
  padding: 0.5rem 1rem;
  line-height: 2.5;
  border-radius: 8px;
  font-weight: 700;
  margin-left: 1vw;
}
.example .imAd #title #titleWords > span:nth-child(1) {
  background: var(--color2);
  color: var(--hsp2);
}
.example .imAd #title #titleWords > span:nth-child(2) {
  background: var(--color3);
  color: var(--hsp3);
}
.example .imAd #title #titleWords > span:nth-child(3) {
  background: var(--color4);
  color: var(--hsp4);
}
.example .imAd button {
  font-family: "Raleway", sans-serif;
  color: var(--color1);
  background-color: var(--mainDark);
  display: block;
  font-size: 1.2rem;
  border: none;
  padding: 1.5rem 4vw;
  width: fit-content;
  margin: 1.4rem 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  width: max-content;
  border-radius: 2rem;
  animation: buttonAnimation 1s infinite;
  font-weight: 500;
  background-color: var(--color3);
  color: var(--hsp3);
}
.example .imAd button:hover {
  background-color: var(--color1);
  color: var(--mainDark);
}
.example .imAd #palette {
  border: 5px solid var(--mainDark);
  border-bottom: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.example .imAd #palette > div {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  padding: 0.5rem 1rem;
}
.example .imAd #palette > div:nth-child(1) {
  background: var(--color2);
  color: var(--hsp2);
}
.example .imAd #palette > div:nth-child(2) {
  background: var(--color3);
  color: var(--hsp3);
}
.example .imAd #palette > div:nth-child(3) {
  background: var(--color4);
  color: var(--hsp4);
}
.example .imAd #site {
  height: 380px;
  overflow: hidden;
  border: 5px solid var(--mainDark);
}
.example .imAd #site nav {
  background: var(--color3);
  height: 15%;
  box-sizing: border-box;
  padding: 10px 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}
.example .imAd #site nav #logo {
  height: 90%;
  width: 8%;
  background: var(--color2);
}
.example .imAd #site nav #links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}
.example .imAd #site nav #links > div {
  padding: 0.5rem 1rem;
  background: var(--color2);
}
.example .imAd #site nav #links > div:last-child {
  background: var(--color4);
}
.example .imAd #site main {
  background: var(--color2);
  height: 70%;
  padding: 5% 2vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.example .imAd #site main > * {
  box-sizing: border-box;
  width: 50%;
}
.example .imAd #site main #img {
  height: 100%;
  width: 50%;
  background: var(--color3);
}
.example .imAd #site main #text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.example .imAd #site main #text #h1 {
  width: 100%;
  height: 20px;
  background-color: var(--color3);
}
.example .imAd #site main #text p {
  width: 50%;
  height: 20px;
  background-color: var(--color3);
}
.example .imAd #site footer {
  height: 15%;
  box-sizing: border-box;
  padding: 10px 1vw;
}
.example footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2vw;
  align-items: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  font-family: "Raleway", sans-serif;
  width: 100%;
  font-size: 0.8rem;
  padding: 40px 4vw;
  box-sizing: border-box;
}
.example footer p {
  width: 50%;
  line-height: 1.5;
}
.example footer #faIcons {
  padding: 0 2vw;
}
.example footer #faIcons a {
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.example footer #faIcons a:hover {
  color: var(--color2) !important;
  background-color: var(--hsp2);
}
.Game {
  background-color: var(--mainDark);
  color: var(--color1);
  height: 100vh;
  box-sizing: border-box;
  padding: 20px 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.Game .palette {
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
.Game .palette .color {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Game .palette .color .gen {
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
  padding: 0.5rem 1rem;
  font-weight: 700;
  margin: 0;
  width: fit-content;
  border-radius: 8px 0;
  border: 2px solid var(--mainDark);
  background-color: var(--mainDark);
  color: var(--color1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: auto;
  gap: 0;
}
.Game .palette .color .gen p {
  margin: 0;
}
.Game .palette .color img {
  width: 50%;
  image-rendering: pixelated;
  z-index: 2;
}
.Game .palette .color img.sillhouette {
  filter: brightness(0);
}
.Game .palette .color img.gameover {
  width: 100%;
}
.Game .palette .color img.type {
  align-self: flex-end;
  width: 50%;
  max-width: 30%;
  bottom: 0;
  margin-bottom: 20px;
}
.Game .inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  width: 100%;
  padding: 1rem 2vw;
  box-sizing: border-box;
  position: relative;
}
.Game .inputWrapper input {
  width: 100%;
  padding: 1rem 2vw;
  border-radius: 8px;
  border: 2px solid var(--hsp2);
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
  color: var(--hsp2);
}
.Game .inputWrapper input::placeholder {
  color: var(--hsp2);
  opacity: 0.5;
}
.Game .inputWrapper #suggestionsWrapper {
  position: absolute;
  top: 100%;
  width: 90%;
  overflow-y: scroll;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: 1px solid var(--hsp3);
  box-sizing: border-box;
}
.Game .inputWrapper #suggestionsWrapper.empty {
  display: none;
}
.Game .inputWrapper #suggestionsWrapper #suggestions {
  max-height: 200px;
  width: 100%;
}
.Game .inputWrapper #suggestionsWrapper #suggestions .suggestion {
  margin-top: 1px;
  top: 0;
  position: relative;
  width: 100%;
  border: none;
  padding: 10px 0;
  font-family: var(--font1);
  font-size: 1.2rem;
  min-height: 60px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
}
.Game .inputWrapper #suggestionsWrapper #suggestions .suggestion:hover {
  border-radius: 1rem;
  transform: scale(0.9);
}
.Game .inputWrapper #suggestionsWrapper #suggestions .suggestion:first-child {
  margin-top: 0;
}
.Game .inputWrapper #suggestionsWrapper #suggestions .suggestion span img {
  width: 40px;
  vertical-align: bottom;
  margin-left: 10px;
}
.Game .gameModes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color1);
  justify-content: space-between;
  box-sizing: border-box;
  background-color: var(--color1);
  border-radius: 2rem;
  overflow: hidden;
  width: 100%;
}
.Game .gameModes button {
  padding: 1rem 2vw;
  font-size: 1.2rem;
  border: none;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  width: 50%;
  box-sizing: border-box;
  height: 100%;
}
.Game .gameModes button:hover {
  background-color: var(--color3);
  color: var(--hsp3);
}
.Game .gameModes.normal button:nth-child(1) {
  background-color: #055c1c;
  color: var(--hsp3);
}
.Game .gameModes.shiny button:nth-child(2) {
  background-color: #055c1c;
  color: var(--hsp3);
}
.Game .gameModes.hard button:nth-child(3) {
  background-color: var(--color3);
  color: var(--hsp3);
}
.Game .giveUp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  padding: 1rem 2vw;
  margin: 2rem auto;
  box-sizing: border-box;
  align-self: center;
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  color: #c13333;
  border: 2px solid #c13333;
  border-radius: 8px;
  background: transparent;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}
.Game .giveUp:hover {
  background-color: #c13333;
  color: var(--color1);
  border: none;
}
.Game .popupWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.Game .popupWrapper.hidden {
  display: none;
}
.Game .popupWrapper .backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}
.Game dialog[open] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  top: 50%;
  transform: translateY(-60%);
  width: 80%;
  box-sizing: border-box;
  padding: 2rem 4vw;
  border-radius: 2rem;
  z-index: 2;
  border: none;
  box-shadow: 0 0 100px #000000;
  max-height: 80%;
}
.Game dialog[open] .confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  opacity: 0.2;
}
.Game dialog[open] h2 {
  text-align: center;
}
.Game dialog[open] .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  width: 100%;
}
.Game dialog[open] .buttons button {
  width: 100%;
}
.Game dialog[open] .buttons button.no {
  background-color: #ffffff;
  color: #c13333;
  border: 1px solid #c13333;
}
.Game dialog[open] a {
  text-align: center;
  padding: 1rem 2vw;
  border-radius: 8px;
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
  color: var(--mainDark);
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  animation: buttonAnimation 2s infinite;
  display: flex;
  z-index: 2;
}
.Game dialog[open] button {
  z-index: 2;
  padding: 1rem 2vw;
  font-size: 1.2rem;
  border: none;
  font-family: "Raleway", sans-serif;
  color: var(--color1);
  background-color: var(--color3);
  cursor: pointer;
  transition: all 200ms ease-in-out;
  box-sizing: border-box;
  border-radius: 8px;
}
.Game dialog[open] .pokemon {
  width: 50%;
  z-index: 2;
  filter: drop-shadow(0 0 10px #ffffff);
  image-rendering: pixelated;
}
@media screen and (max-width: 768px) {
  .bmc-btn-container {
    width: 50%;
    right: 5%;
  }
  .bmc-btn-container .bmc-btn {
    width: 100%;
    min-width: 50%;
    font-size: 0.8rem;
  }
  .bmc-btn-container .bmc-btn svg {
    height: 1.6rem !important;
  }
  .App {
    flex-direction: column;
  }
  .example,
  .poke {
    width: 100vw;
    position: relative;
  }
  .pokeCard {
    height: 80vh;
    border: 0px solid #121212;
    gap: 0vh;
    padding: 10vw;
    width: 80vw;
  }
  .pokeCard #logo #pokeball {
    left: 10%;
    top: 1.2rem;
  }
  .pokeCard #saveBtn button {
    top: auto;
    bottom: 1.2rem;
    font-size: 1.2rem;
    background-color: var(--color4);
    color: var(--hsp4);
  }
  .pokeCard #saveBtn button svg {
    font-size: 1.4rem;
  }
  .pokeCard #profileDashboard {
    gap: 2vw;
    width: 100%;
    z-index: 3;
    padding: 0 2vw;
  }
  .pokeCard #profileDashboard #profileDashboardContent {
    border-radius: 1rem;
    padding: 2rem 0;
  }
  .pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser {
    flex-direction: column;
  }
  .pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserImg {
    width: 50px;
    height: 50px;
  }
  .pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserImg img {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserDetails {
    font-size: 1rem;
  }
  .pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserBtns {
    width: 100%;
    box-sizing: border-box;
  }
  .pokeCard #profileDashboard #profileDashboardContent #profileDashboardUser #profileDashboardUserBtns button {
    margin: 0;
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
  .pokeCard #profileDashboard #profileDashboardContent #profileDashboardBody #profileDashboardSaved #profileDashboardSavedList {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    width: 100%;
  }
  .pokeCard #profileMenu {
    left: auto;
    right: 5vw;
    top: 1.5rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
  .pokeCard #profileMenu #profileBtn button {
    max-width: 2.5rem;
  }
  .pokeCard #imgData {
    height: 50vw;
    width: 50vw;
  }
  .pokeCard #suggestionsWrapper #suggestions {
    max-height: 30vh;
  }
  .pokeCard .labelInput #shinyBtn {
    top: 58%;
    right: 0;
    font-size: 1.2rem;
  }
  .pokeCard select {
    font-size: 1.2rem !important;
  }
  .example main #darkMode {
    width: 50%;
  }
  .example main #darkMode div {
    width: 35%;
  }
  .example #cards #loadMore {
    font-size: 1.2rem;
  }
  .example #pkmnInfo {
    flex-direction: column;
  }
  .example #pkmnInfo .txtWrapper {
    width: 100%;
    padding: 0px 2vw;
    box-sizing: border-box;
  }
  .example #pkmnInfo .imgWrapper {
    height: 400px;
    width: 100%;
  }
  .example #pkmnInfo .imgWrapper #hiddenPkmn {
    background-size: 60% auto;
  }
  .example #ballType {
    padding: 20px 6vw 20px;
  }
  .example #ballType h2 {
    font-size: 2rem;
  }
  .example #ballType .ballTypesWrapper {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .example #ballType .ballTypesWrapper .ballMiniSection {
    width: 80%;
    padding: 20px 10vw;
  }
  .example #ballType .ballTypesWrapper .ballMiniSection p {
    font-size: 1.4rem;
  }
  .example #ballType .ballTypesWrapper .ballMiniSection .ball {
    gap: 2vw;
  }
  .example #ballType .ballTypesWrapper .ballMiniSection .ball > * {
    width: 50%;
    height: 100px;
  }
  .example #shareWidgets {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
  .example #shareWidgets .tweet-popup {
    width: 100%;
    padding: 20px 2vw;
  }
  .example #shareWidgets .tweet-popup .tweet-input {
    width: 90%;
  }
  .example .imAd {
    flex-direction: column-reverse;
    text-align: center;
  }
  .example .imAd > * {
    width: 100%;
  }
  .example .imAd #info {
    text-align: left;
    width: 100%;
  }
  .example .imAd #info #titleWords {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0;
    gap: 20px;
  }
  .example .imAd #info #titleWords span {
    margin: 0;
    padding: 0 5vw;
  }
  .example .imAd #palette {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .example .imAd #palette > div {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    padding: 0.5rem 1rem;
  }
  .example .imAd #site main {
    gap: 5vw;
  }
  .example footer {
    flex-direction: column;
    padding: 20px 4vw;
  }
  .example footer p {
    width: 100%;
  }
  .example footer #faIcons a {
    font-size: 2rem;
  }
}
.figma {
  background-color: #ffffff;
  width: 60%;
  height: calc((3.6 * 0.6 / 3) * 70vw);
  display: grid;
  grid-template-columns: 1fr 1.8fr 1fr;
  gap: 1vw;
  align-self: center;
  border: 1px solid #000000;
}
.figma > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.figma > *::after {
  margin-bottom: 15rem;
  font-size: 1.4rem;
  font-weight: 1000;
}
.figma .gridSection1 {
  background-color: var(--color2);
}
.figma .gridSection1::after {
  content: var(--color2word);
  color: var(--hsp2);
}
.figma .gridSection2 {
  background-color: var(--color3);
  position: relative;
}
.figma .gridSection2 .figmaLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
  position: absolute;
  top: 0;
}
.figma .gridSection2 .figmaLogo img {
  height: 20px;
}
.figma .gridSection2 .figmaLogo p {
  font-family: "Oswald", sans-serif;
  color: var(--hsp3);
  font-size: 1.2rem;
}
.figma .gridSection2 .pkmn {
  background: var(--artURL);
  background-size: cover;
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 150%;
  height: 60%;
  overflow-x: visible;
  position: absolute;
  bottom: 0rem;
}
.figma .gridSection2 .sfl {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 0rem 1rem;
  position: absolute;
  bottom: 3rem;
  font-size: 1.4rem;
  height: 3rem;
  font-weight: 1000;
}
.figma .gridSection2 .sfl svg {
  margin-left: 0.5vw;
  vertical-align: middle;
}
.figma .gridSection2::after {
  content: var(--color3word);
  color: var(--hsp3);
}
.figma .gridSection3 {
  background-color: var(--color4);
}
.figma .gridSection3::after {
  content: var(--color4word);
  color: var(--hsp4);
}
#ballType {
  padding: 20px 6vw 60px;
  box-sizing: border-box;
}
#ballType h2 {
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.2;
  font-family: "Raleway", sans-serif;
  margin: 2rem 0 0 0;
}
#ballType .ballTypesWrapper {
  display: flex;
  gap: 2vw;
  box-sizing: border-box;
  width: 100%;
  margin: 2rem 0;
}
#ballType .ballTypesWrapper .ballMiniSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  background-color: var(--hsp2);
  color: var(--anti_hsp2);
  padding: 20px 2vw;
  border-radius: 12px;
  box-sizing: border-box;
}
#ballType .ballTypesWrapper .ballMiniSection p {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
  font-weight: 1000;
}
#ballType .ballTypesWrapper .ballMiniSection img {
  height: auto;
  min-height: 100px;
  image-rendering: pixelated;
}
#ballType .ballTypesWrapper .ballMiniSection .typeImg {
  min-height: auto;
  margin-top: 1rem;
  width: auto;
  height: 50px;
}
#ballType .ballTypesWrapper .ballMiniSection .ball {
  display: flex;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  gap: 0.2vw;
}
#ballType .ballTypesWrapper .ballMiniSection .ball > * {
  width: 33%;
  height: 100px;
}
#mainSlide > *::after,
#lastSlide > *::after {
  display: none;
}
#mainSlide .gridSection2,
#lastSlide .gridSection2 {
  overflow: visible;
}
#mainSlide .gridSection2::after,
#lastSlide .gridSection2::after {
  display: flex;
  content: "Steal these palettes";
  text-align: center;
  font-size: 3.6rem;
  margin-bottom: 22rem;
}
#mainSlide .gridSection2 .pkmn,
#lastSlide .gridSection2 .pkmn {
  width: 170%;
}
#mainSlide .edition,
#lastSlide .edition {
  color: #ffffff;
  background-color: #000000;
  border-radius: 12rem;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 1.2rem;
  font-weight: 1000;
  padding: 0.5rem 1rem;
  z-index: 9;
  transform: translate(80%, -200%) rotate(-5deg);
}
#mainSlide .edition.visible,
#lastSlide .edition.visible {
  display: flex;
}
.figma,
#screenshotBtn,
#toggleSpecialEdition {
  display: none;
}
